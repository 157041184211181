import React from "react"
import { Link } from "gatsby"

import KeyFeature from "../components/key-feature"
import SEO from "../components/seo"

import indexStyles from "./index.module.scss"
import Button from "../components/button"

const keyFeatures = [
  {
    title: "Alt-Med Plattform",
    body: (
      <>
        Alt-Med ist eine Webseite & eine mobile App <br></br>
        
        Start in Zürich - November 2020 
      </>
    ),
  },
  {
    title: "Ziele",
    body: (
      <>
        - Alt-Med verbindet Kunden und Praktizierer. <br></br>
        -  Wir machen die heute noch verfügbaren Termine leichter zugänglich.
      </>
    ),
  },
  {
    title: "Alt-Med Startup",
    body: (
      <>
        Alt-Med ist eine App der 
        <a href="https://block-lab.ch"> Block Lab GmbH</a>
        <br></br>
        Gegründet 2019<br></br>
        <Link to="/doc/about">mehr zu uns</Link>
      </>
    ),
  },
  // {
  //   title: "Get the test data you need - fast",
  //   body: (
  //     <>
  //       Galasa can integrate with your test data strategy by helping you to
  //       provision new or existing test data records. Galasa can also lock test
  //       data so that many tests can run in parallel against the same test data
  //       pool, making it easy to repeat tests under identical conditions for
  //       swift debugging. Provisioned environments are automatically
  //       deprovisioned at test completion, leaving a clean test system state
  //       ready for the next tests.
  //     </>
  //   ),
  // },
  
  {
    title: "Support",
    body: (
      <>
        Bei Fragen dürfen Sie uns gerne Kontaktieren. <br></br>
        <Link to="/doc/support">Support</Link>
      </>
    ),
  },
  // {
  //   title: "Keep on growing – Galasa grows with you",
  //   body: (
  //     <>
  //       Galasa is open source, so can be extended to support additional tooling
  //       with no vendor lock-in and no initial cost. The framework supports
  //       enterprise level through put, as the test workload can be scaled
  //       horizontally in its own cloud environment.
  //     </>
  //   ),
  // },
]

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <div className={indexStyles.heroContainer}>
      <div>
        <h1 className={indexStyles.heroTitle}>
          Alt-Med Dokumentation
        </h1>
        {/* <p className={indexStyles.heroDescription}>
          Erklärung der Alt-Med Plattform
        </p> */}
        <br></br>
        <div className={indexStyles.heroButtons}>
          <Button target={"/docs"} flash={true} primary={true}>
Start          </Button>
          <Button target={"/about"} flash={true} primary={true}>
            über uns
          </Button>
          <Button target={"/support"} flash={true} primary={true}>
            Kontakt
          </Button>
        </div>
      </div>
    </div>
    <div className={indexStyles.keyFeatureContainer}>
      {keyFeatures.map((item, i) => {
        return (
          <KeyFeature title={item.title} index={i}>
            {item.body}
          </KeyFeature>
        )
      })}
    </div>
  </>
)

export default IndexPage
